import { SortedColumn } from '../models/sorted-column.model';
import { isNotNullOrUndefined } from '../utils/common.utils';
import ApplicationSetting from './application.constants';

export const ExpressUrl = `${ApplicationSetting.apiUrl}/api`;

export const ApiConstants = {
  companyExpressSetup: {
    root: `${ExpressUrl}/companyExpressSetup`,
    getCompanyExpressSetupInfo(companyId: number) {
      return `${this.root}?companyId=${companyId}`;
    },
    getCompanyId(alias: string) {
      return `${this.root}/alias/${alias}`;
    },
  },
  company: {
    root: `${ExpressUrl}/company`,
    getListCompanyExpress(companyId: number, email: string, searchText?: string) {
      return `${this.root}?companyId=${companyId}&email=${email}&searchText=${searchText ?? ''}`;
    },
    getContactList(companyId: number, companyOwner: number, sortedColumn?: SortedColumn) {
      let url = `${this.root}/${companyId}/contacts?companyOwner=${companyOwner}`;
      if (isNotNullOrUndefined(sortedColumn)) {
        url = url + `&columnName=${sortedColumn?.columnName}&isDescending=${sortedColumn.isDescending}`;
      }
      return url;
    },
    getCompanyById(companyId: number) {
      return `${this.root}/${companyId}`;
    },
    inviteExpressUser(contactId: number) {
      return `${this.root}/express/invite-user?contactId=${contactId}`;
    },
    addNewUsersExpress() {
      return `${this.root}/express/users`;
    },
    getCompanyExpressPreviewInfo(alias: string, languageId: number) {
      return `${this.root}/express/preview?alias=${alias}&languageId=${languageId}`;
    },
  },
  files: {
    root: `${ExpressUrl}/files`,
    getFileAsync(imageContent: string) {
      return `${this.root}?imageContent=${imageContent}`;
    },
  },
  userAccount: {
    root: `${ExpressUrl}/userAccount`,
    getInformationByIdAsync(userAccountId: number) {
      return `${this.root}/${userAccountId}`;
    },
    updatePreferredLanguage(languageId: number) {
      return `${this.root}/preferred-language?languageId=${languageId}`;
    },
  },
  common: {
    root: `${ExpressUrl}/common`,
    getContactInfo() {
      return `${this.root}/contact`;
    },
    getHomeViewInfo(languageId: number) {
      return `${this.root}/home-view?languageId=${languageId}`;
    },
    checkIfAlreadyHasRequest() {
      return `${this.root}/check-has-request`;
    },
    requestAUser() {
      return `${this.root}/request-a-user`;
    },
    checkAccessRights() {
      return `${this.root}/check-access-rights`;
    },
    loadTranslation() {
      return `${this.root}/languages`;
    },
  },
  document: {
    root: `${ExpressUrl}/document`,
    getDocuments() {
      return `${this.root}`;
    },
    getGroupDocuments() {
      return `${this.root}/GetGroupDocuments`;
    },
    getDocumentTypesWithFilters() {
      return `${this.root}/GetDocumentTypes`;
    },
    getImageDocument(id: number) {
      return `${this.root}/GetImageDocument?id=${id}`;
    },
    getUrlDownloadDocument(id: number) {
      return `${this.root}/GetDocumentBinary?id=${id}`;
    },
  },
  capturedEventAlert: {
    root: `${ExpressUrl}/capturedEventAlert`,
    getEventAlertByEmail(companyOwner: number, email: string, languageId: number) {
      return `${this.root}?companyOwner=${companyOwner}&email=${email}&languageId=${languageId}`;
    },
    getEventAlertByEmailPublic(companyOwner: number, param: string, languageId: number) {
      return `${this.root}/get-captured-event-alert?companyOwner=${companyOwner}&param=${param}&languageId=${languageId}`;
    },
    saveEventAlert() {
      return `${this.root}`;
    },
    saveEventAlertPublic() {
      return `${this.root}/save-captured-event-alert`;
    },
  },
  equipment: {
    root: `${ExpressUrl}/equipment`,
    getEquipmentDetailById(id: number, languageId: number) {
      return `${this.root}/EquipmentDetail?equipmentId=${id}&languageId=${languageId}`;
    },
    getThumbnailImageFileByImageContentId(id: string) {
      return `${this.root}/GetThumbnailImageFile?imageContentId=${id}`;
    },
    getEquipments() {
      return `${this.root}/GetEquipments`;
    },
    getGroupEquipments() {
      return `${this.root}/GetGroupEquipments`;
    },
    searchControlCategoriesByText(text: string, languageId: number, excludeNA: boolean) {
      return `${this.root}/SearchControlCategoriesByText?text=${text ?? ''}&languageId=${languageId}&excludeNA=${excludeNA}`;
    },
    searchLocationsByText(companyId: number, text?: string) {
      return `${this.root}/searchLocationsByText?companyId=${companyId}&text=${text ?? ''}`;
    },
    getPagedEquipmentPictures() {
      return `${this.root}/getPagedEquipmentImages`;
    },
    getProperties(id: number, languageId: number) {
      return `${this.root}/properties?equipmentId=${id}&languageId=${languageId}`;
    },
    getEquipmentInfoById(id: number) {
      return `${this.root}/EquipmentDetailInfo?equipmentId=${id}`;
    },
    getJobInterValList(id: number, languageId: number) {
      return `${this.root}/GetJobInterValList?equipmentId=${id}&languageId=${languageId}`;
    },
    createExportMessage() {
      return `${this.root}/ReportExcel`;
    },
    checkStatusExporting(taskQueueId: number) {
      return `${this.root}/CheckStatus?taskQueueId=${taskQueueId}`;
    },
  },
};
